import { Box, Grid, IconButton, Typography } from '@mui/material';
import TmPencil from '../../../icons/TmPencil';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { ShowForPermission } from '../../common/permissions/ShowForPermission';
import { useState } from 'react';
import { useCompanyDetailsContext } from './context/CompanyDetailsContextProvider';
import { EditCompanyMainInfoDialog } from './dialogs/EditCompanyMainInfoDialog';
import { DepartmentsAndDispatchersTable } from './DepartmentsAndDispatchersTable';
import AcceptLabel from '../../common/labels/AcceptLabel';
import DenyLabel from '../../common/labels/DenyLabel';
import TmContractAdd from '../../../icons/TmContractAdd';
import TmContractBlock from '../../../icons/TmContractBlock';
import { companiesService } from '../../../services/companiesService';
import { AgreementState } from '../../../models/enums/agreementState';
import { RoleType } from '../../../models/enums/roleType';
import { ShowForRoles } from '../../common/permissions/ShowForRoles';
import Spinner from '../../common/Spinner';

export const CompanyMainInfoTab: React.FC = () => {
    const { company, setCompany } = useCompanyDetailsContext();
    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleEditPopupToggle(): void {
        setEditPopupOpen(!isEditPopupOpen);
    }

    const handleAgreementToggle = async () => {
        setLoading(true)
        try {
            const res = await companiesService.changeAgreementState(
              company?.id as number,
              {
                  agreementStateId: company?.agreementState?.isValid
                    ? AgreementState.disable
                    : AgreementState.active
              }
            )

            if (res.data.success) {
                setCompany((prevCompany) => {
                    if (!prevCompany) {
                        return prevCompany;
                    }

                    return {
                        ...prevCompany,
                        agreementState: {
                            ...prevCompany!.agreementState!,
                            isValid: !prevCompany.agreementState?.isValid,
                        },
                    };
                });
            }
        }
        catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Box>
            <Grid container spacing={2} mt={0.5}>
                <Grid item xs={8}>
                    <Box className="gbr" px={2} pb={2} sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Grid container>
                            <Grid item xs={10} pt={2}>
                                <Typography variant="h5" fontWeight="bold">
                                    Общая информация
                                </Typography>
                            </Grid>
                            <Grid item xs={2} pt={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <ShowForPermission catalog={CategoryType.Companies} permission={PermissionType.Edit}>
                                    <IconButton className="list-item" sx={{ px: '20px' }} onClick={handleEditPopupToggle}>
                                        <TmPencil fill="none" stroke="white" />
                                        &nbsp;&nbsp;
                                        <Typography fontWeight="bold">Редактировать</Typography>
                                    </IconButton>
                                </ShowForPermission>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Название организации</Typography>
                                    <Typography className="rtb">{company?.name}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Адрес</Typography>
                                    <Typography className="rtb">{company?.address}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>ИНН</Typography>
                                    <Typography className="rtb">{company?.inn}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Ответственное лицо</Typography>
                                    <Typography className="rtb">{company?.responsibleFio}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Телефон</Typography>
                                    <Typography className="rtb">{company?.phone}</Typography>
                                </Box>
                                <Box>
                                    <Typography className="ltb" variant="body1" mr={1}>Терминалы</Typography>
                                    <Typography className="rtb">{company?.terminals.map(t => t.name).join(', ')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid item display="flex" xs={4}>
                    <Box className="gbr" p={2} sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h5" fontWeight="bold">
                            Договор
                        </Typography>
                        <Box sx={{position: 'relative'}}>
                            <Typography className="ltb" variant="body1" mr={1}>
                                Статус договора
                            </Typography>
                            <Typography className="rtb" sx={{fontSize: "14px"}}>
                                {company?.agreementState?.isValid
                                  ? <AcceptLabel>Действует</AcceptLabel>
                                  : <DenyLabel>Заблокирован</DenyLabel>}
                            </Typography>
                            {loading && <Spinner size={40}/>}
                            <ShowForRoles roles={[RoleType.Admin]}>
                                <IconButton disabled={loading} sx={{ display: "flex", mt: 2, px: 2 }} className="list-item-outline" onClick={() => handleAgreementToggle()}>
                                    {company?.agreementState?.isValid ? <TmContractBlock stroke="#9588e8" /> : <TmContractAdd stroke="#9588e8" />}
                                    &nbsp;&nbsp;
                                    <Typography fontWeight="bold">
                                        {company?.agreementState?.isValid ? 'Заблокировать договор' : 'Возобновить договор'}
                                    </Typography>
                                </IconButton>
                            </ShowForRoles>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Typography pb={1} variant="h5" fontWeight="bold">
                        Объекты и диспетчеры
                    </Typography>
                    <Box className="gbr" px={2}>

                        <Box>
                            <DepartmentsAndDispatchersTable/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <EditCompanyMainInfoDialog open={isEditPopupOpen} company={company!} onClose={handleEditPopupToggle} onSave={setCompany}/>
        </Box>
    );
};
